<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';
export default {

    components: {
        Layout,
        EasyDataTable,
        // ReportStatus 
    },
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            searchValueTable: '',
            selectFilterSort: null,
            headers: [
                { text: "Full Name", value: "disclosure" },
                { text: "Email", value: "email" },
                { text: "Action", value: "actionBtn" },
            ],

            arrItem: [],
            totalApplicant: 0,
            totalEarning: 0,
            totalStaff: 0,
        }
    },
    mounted() {
        this.totalData();
        this.getAllApplicant();

    },
    created() {
        this.totalData();
        this.getAllApplicant();

    },

    methods: {
        totalData() {
            axios.post(this.app_URL + "api/totalData").then((res) => {
                this.totalStaff = res.data.staff;
                this.totalApplicant = res.data.applicant;
                this.totalEarning = res.data.earning;
            })
        },
        getAllApplicant() {
            axios.post(this.app_URL + "api/getAllApplicant").then(res => {
                this.arrItem = res.data;
            })
        },
        encode(id) {
            return encodeURIComponent(
                CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
            );
        }

    },
};

</script>

<template>
    <Layout>
        <main class="py-4">

            <div class="card">
                <div class="card-body align-items-center p-3 rounded adminDashboardDesign d-flex">
                    <div class="col">
                        <h2 class="font-size-22 text-theme  m-0">Dashboard</h2>
                    </div>
                    <div class="col-auto">
                        <img src="../../../assets/images/citizenship/admin.png" width="100">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-center rounded py-3">
                                <div class="bg-lightprimary text-center">
                                    <img src="../../../assets/images/citizenship/admin.png" width="50">
                                    <div class="fw-bold mt-3 text-theme">Total Clients</div>
                                    <h4 class="text-h4 mt-1 text-dark">{{ this.totalApplicant }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-center rounded py-3">
                                <div class="bg-lightwarning text-center">
                                    <img src="../../../assets/images/citizenship/earning.png" width="66">
                                    <div class="fw-bold mt-3 text-theme">Total Earning</div>
                                    <h4 class="text-h4 mt-1 text-dark">${{ this.totalEarning }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-center rounded py-3">
                                <div class="bg-lightwarning text-center">
                                    <img src="../../../assets/images/citizenship/user.png" width="66">
                                    <div class="fw-bold mt-3 text-theme">Total Staff</div>
                                    <h4 class="text-h4 mt-1 text-dark">{{ this.totalStaff }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-lg-6">
                            <h2 class="font-size-18 mb-3">All List</h2>
                        </div>
                        <div class="col-lg-6 mt-3 mt-md-0">
                            <div class="">
                                <input type="text" v-model="searchValueTable" placeholder="Search..."
                                    class="customInputForm form-control">
                            </div>
                        </div>
                    </div>
                    <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
                        theme-color="#df6a0d" :rows-per-page="10" buttons-pagination
                        table-class-name="table-custom-style noWrapHeading">
                        <template #item-disclosure="{ disclosure }">
                            <p class="mb-0 text-nowrap fw-medium">{{ disclosure ? disclosure.firstName : '' }} {{ disclosure
                              ? disclosure.surName : '' }}</p>
                          </template>
                        <template #item-actionBtn="{ id }">
                            <router-link :to="`/allApplicants/${this.encode(id)}`" class="btn btn-sm btn-theme-dark">
                                <i class="mdi mdi-eye"></i>
                                View
                            </router-link>
                        </template>
                    </EasyDataTable>
                </div>
            </div>
        </main>
    </Layout>
</template>